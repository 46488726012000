import React, { useEffect } from "react";

import Grid from "@mui/material/Grid";
import CardComponent from "../../components/Card/Card";
import ButtonComponent from "../../components/Button/Button";

import Container from "@mui/material/Container";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";

import { useAlert } from "../../contexts/AlertProvider";
import LoadComponent from "../../components/Load/Load";

import "./Settings.css";
import ModalAddSettings from "./Modals/ModalAdd";
import { getSettingsAPI } from "../../services/api/settingService";
import ModalEditSettings from "./Modals/ModalEdit";
import ModalDeleteSettings from "./Modals/ModalDelete";

import { getScreenLocalStorage } from "../../services/localStorage/screenLocalStorage";
import Screen from "../../models/Screen";
import Setting from "../../models/Setting";
import CarouselComponent from "../../components/Carousel/Carousel";

const SettingsPage = () => {
  const [screen, setScreen] = React.useState(null);
  const [screenSettings, setScreenSettings] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const { doErrorAlertVisible } = useAlert();

  useEffect(() => {
    const screen = verifyScreen();
    if (screen) {
      getAllSettings(screen.id);

      const intervalId = setInterval(() => {
        getAllSettings(screen.id);
      }, 300000); // get in 5 and 5 minutes

      return () => {
        clearInterval(intervalId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyScreen = () => {
    const screen = Screen.setObject(getScreenLocalStorage());
    if (screen && screen !== null && screen.id) {
      setScreen(screen);
      return screen;
    }
    doErrorAlertVisible("Uma tela precisa ser selecionada!");
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);

    return false;
  };

  const getAllSettings = (screenID) => {
    getSettingsAPI(screenID)
      .then((settings) => {
        setScreenSettings(Setting.createObjectFromArray(settings));
      })
      .catch((error) => {
        doErrorAlertVisible("Erro ao listar configuração da tela!");
        console.log("Error: ", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <LoadComponent isLoad={isLoading} />;
  }

  return (
    <Container className="settings-container">
      <div>
        <h4>{screen.name}</h4>
        <div>{screen.description}</div>
      </div>
      <ModalAddSettings
        getAllSettings={getAllSettings}
        screenID={screen.id}
        child={
          <ButtonComponent
            label={"Adicionar Configuração da Tela"}
            className="settings-button"
          />
        }
      />

      <Grid container className="screens-cards-container">
        {screenSettings.map((settings, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            md={4}
            xl={4}
            key={index}
            className="screens-grid"
          >
            <CardComponent
              title={settings.name}
              image={
                <div className="settings-carousel-container">
                  <CarouselComponent
                    images={[settings.image]}
                    updateTimeInSeconds={300}
                    height={"230rem"}
                  />
                </div>
              }
              body={
                settings.lastUpdate?(
                  <div>
                    <div style={{ fontWeight: 500 }}>Atualizada:</div>
                    {settings.lastUpdate}
                  </div>
                ): <></>
              }
              description={settings.description}
              options={
                <div className="settings-icons-container">
                  {/* <ModalEditSettings
                    settings={settings}
                    setSettings={setScreenSettings}
                    child={
                      <div className="icon-container settings-setting-icon-container">
                        <SettingsIcon />
                        <div className="settings-setting-icon-text">
                          Configurar
                        </div>
                      </div>
                    }
                  /> */}

                  <div className="settings-delete-icon-width">
                    <ModalDeleteSettings
                      id={settings.id}
                      name={settings.name}
                      setSettings={setScreenSettings}
                      child={
                        <div className="icon-container settings-delete-icon-container">
                          <DeleteIcon />
                          <div className="settings-delete-icon-text">
                            Excluir
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
              }
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SettingsPage;
