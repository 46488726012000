import PropTypes from "prop-types";

class Auth {
  constructor({ id, token, userName, expiration }) {
    this.id = id;
    this.token = token;
    this.userName = userName;
    this.expiration = expiration;
  }

  static setObject(data) {
    return new Auth({
      id: data.id,
      token: data.token,
      userName: data.userName,
      expiration: data.expiration,
    });
  }
}

Auth.propTypes = {
  id: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  expiration: PropTypes.number.isRequired,
};


export default Auth;
