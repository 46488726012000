import { SCREEN_LOCAL_STORAGE } from "../../constants/constants";
import { getLocalStorageItem, setLocalStorageItem } from "./localStorage";

function setScreenLocalStorage(screen) {
  return setLocalStorageItem(SCREEN_LOCAL_STORAGE, screen);
}

function getScreenLocalStorage() {
  return getLocalStorageItem(SCREEN_LOCAL_STORAGE);
}

export { setScreenLocalStorage, getScreenLocalStorage };
