import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";

import "./Card.css";

const CardComponent = ({ ...props }) => {
  return (
    <Card bg={"light"} text={"dark"} className="card-container">
      <Card.Header className="card-text-title">{props.options}</Card.Header>
      <div className="card-image-container">
        {props.image && <div className="card-image">{props.image}</div>}
      </div>
      <Card.Body>
        <Card.Title className="card-text"> {props.title}</Card.Title>
        <Card.Text className="card-text">{props.description}</Card.Text>
        <div>{props.body}</div>
      </Card.Body>
    </Card>
  );
};

CardComponent.propTypes = {
  title: PropTypes.string,
  image: PropTypes.element,
  options: PropTypes.element,
  description: PropTypes.string,
  body: PropTypes.element,
};

export default CardComponent;
