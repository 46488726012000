import React from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";

import "./Modal.css";

const ModalComponent = ({ ...props }) => {
  return (
    <Modal
      show={props.modalShow}
      onHide={() => props.setModalShow(false)}
      aria-labelledby={`contained-modal-title-vcenter ${props.title}`}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id={`contained-modal-title-vcenter ${props.title}`}>
          <div className="modal-title">{props.title}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
    </Modal>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.element.isRequired,
  modalShow: PropTypes.bool.isRequired,
  setModalShow: PropTypes.func.isRequired,
};

export default ModalComponent;
