import React from "react";
import { Routes, Route } from "react-router-dom";
import NavbarComponent from "../Navbar/Navbar";
import ScreensPage from "../../pages/Screens/Screens";
import SettingsPage from "../../pages/Settings/Settings";
import DisplaysPage from "../../pages/Displays/Displays";

function Nav() {
  return (
    <NavbarComponent
      options={[
        { link: "/", label: "GERENCIAR TELAS" },
        // { link: "/displays", label: "PIN" },
      ]}
      label="ViewShot"
    />
  );
}

function PrivateRoutes() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Nav />
              <ScreensPage />
            </>
          }
        />
        <Route
          path="*"
          element={
            <>
              <Nav />
              <ScreensPage />
            </>
          }
        />
        <Route
          path="/settings"
          element={
            <>
              <Nav />
              <SettingsPage />
            </>
          }
        />
        <Route path="/displays" element={<DisplaysPage />} />
      </Routes>
    </>
  );
}

export default PrivateRoutes;
