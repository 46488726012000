import React, { useEffect, useState } from "react";
import { useAlert } from "../../contexts/AlertProvider";
import PINComponent from "../../components/PIN/PIN";
import "./Displays.css";
import CarouselComponent from "../../components/Carousel/Carousel";

import { ChakraProvider } from "@chakra-ui/react";
import { getDisplayByPIN } from "../../services/api/displaysService";
import Display from "../../models/Display";

const DisplaysPage = () => {
  const pinLength = 8;
  const [pin, setPIN] = useState("");
  const [updateTime, setUpdateTime] = useState(0);
  const [imagesURL, setImagesURL] = useState([]);
  const { doErrorAlertVisible } = useAlert();

  useEffect(() => {
    if (pin.length === pinLength) {
      checkPIN();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  const checkPIN = () => {
    getDisplayByPIN(pin)
      .then((displays) => {
        const displaysObj = Display.createObjectFromArray(displays);
        if (displaysObj.length > 0) {
          const arrayDeUrls = displaysObj.map((objeto) => objeto.url);
          setImagesURL(arrayDeUrls);
          setUpdateTime(displaysObj[0].updateTime);
        }
      })
      .catch((error) => {
        console.log(error);
        doErrorAlertVisible("Erro: PIN Incorreto!");
      });
  };

  if (updateTime && imagesURL.length > 0) {
    return (
      <div className={["display-carousel-container-container"]}>
        <div className="display-carousel-container">
          <div className={"display-carousel"}>
            <CarouselComponent
              images={imagesURL}
              updateTimeInSeconds={updateTime}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <ChakraProvider>
      <div className="display-pin-container">
        <div className="display-pin">
          <div className="display-pin-label">PIN</div>
          <PINComponent length={pinLength} setCode={setPIN} />
        </div>
      </div>
    </ChakraProvider>
  );
};

export default DisplaysPage;
