import React from "react";
import PropTypes from "prop-types";
import LoadImage from "../../assets/images/loading.gif";

import "./Load.css";

const LoadComponent = ({ ...props }) => {
  if (!props.isLoad) {
    return <></>;
  }

  return (
    <div className="load-container">
      <img src={LoadImage} alt="Descrição da imagem" className="load-image" />
    </div>
  );
};

LoadComponent.propTypes = {
  isLoad: PropTypes.bool.isRequired,
};

export default LoadComponent;
