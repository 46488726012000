function swapObjectAttributes(object, attributeMappings) {
  if (Array.isArray(object)) {
    return object.map((item) => swapObjectAttributes(item, attributeMappings));
  }

  const newObject = {};

  for (const key in object) {
    const newKey = attributeMappings[key] || key;

    if (typeof object[key] === "object" && object[key] !== null) {
      newObject[newKey] = swapObjectAttributes(object[key], attributeMappings);
    } else {
      try {
        const parsedValue = JSON.parse(object[key]);

        if (typeof parsedValue === "object" && parsedValue !== null) {
          newObject[newKey] = swapObjectAttributes(
            parsedValue,
            attributeMappings
          );
        } else {
          newObject[newKey] = parsedValue;
        }
      } catch (error) {
        newObject[newKey] = object[key];
      }
    }
  }

  return newObject;
}

function formatDateToString(date) {
  const day = addZero(date.getDate());
  const month = addZero(date.getMonth() + 1);
  const year = addZero(date.getFullYear() % 100);
  const hour = addZero(date.getHours());

  return `${day}/${month}/${year} ${hour}h`;
}

function addZero(number) {
  return number < 10 ? "0" + number : number;
}

export { swapObjectAttributes, formatDateToString };
