import PropTypes from "prop-types";

class Display {
  constructor({ id, url, updateTime }) {
    this.id = id;
    this.url = url;
    this.updateTime = updateTime;
  }

  static setObject(data) {
    return new Display({
      id: data.id,
      url: data.url,
      updateTime: data.updateTime,
    });
  }

  static createObjectFromArray(dataArray) {
    return dataArray.map((data) => new Display(data));
  }
}

Display.propTypes = {
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  updateTime: PropTypes.number.isRequired,
};

export default Display;
