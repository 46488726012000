import React from "react";

import ButtonComponent from "../../../components/Button/Button";

import { useAlert } from "../../../contexts/AlertProvider";
import ModalComponent from "../../../components/Modal/Modal";
import { formatDateToString } from "../../../utils/utils";
import FormGroup from "@mui/material/FormGroup";
import PropTypes from "prop-types";

import { deleteSettingsAPI } from "../../../services/api/settingService";

import "./Modals.css";

const ModalDeleteSettings = ({ ...props }) => {
  const { doErrorAlertVisible, doSuccessAlertVisible } = useAlert();
  const [modalShow, setModalShow] = React.useState(false);

  const deleteSettings = () => {
    deleteSettingsAPI(props.id)
      .then((response) => {
        props.setSettings((prevSettings) =>
          prevSettings.filter((newSettings) => newSettings.id !== props.id)
        );
        doSuccessAlertVisible("Configuração excluída com sucesso!");
        setModalShow(false);
      })
      .catch((error) => {
        doErrorAlertVisible("Erro ao excluir configuração!");
        console.log("Error: ", error);
      });
  };

  const handleShowModal = () => {
    setModalShow(true);
  };

  return (
    <span>
      <ModalComponent
        onClick={() => {}}
        modalShow={modalShow}
        setModalShow={setModalShow}
        title={"Deletar Configuração"}
        body={
          <FormGroup className="modal-delete-settings-form">
            A configuração{" "}
            <span className="modal-delete-settings-span">{props.name}</span>{" "}
            será deletada em
            <span className="modal-delete-settings-span">
              {" " + formatDateToString(new Date())}
            </span>
            . Deseja confirmar esta ação?
            <ButtonComponent
              label="Excluir"
              className="modal-delete-settings-button-red"
              onClick={deleteSettings}
            />
          </FormGroup>
        }
      />

      <span onClick={handleShowModal}>{props.child}</span>
    </span>
  );
};

ModalDeleteSettings.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  child: PropTypes.element.isRequired,
  setSettings: PropTypes.func.isRequired,
};

export default ModalDeleteSettings;
