import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import PropTypes from "prop-types";
import LoadingImage from "../../assets/images/loading-image.gif";

import "./Carousel.css";

const CarouselComponent = ({ ...props }) => {
  const [interval] = useState(props.updateTimeInSeconds * 1000);

  const handleImageError = (event, image) => {
    event.target.src = LoadingImage;

    setTimeout(() => {
      event.target.src = `${image}?${Math.random()}`;
    }, 5000);
  };

  return (
    <Carousel controls={false} indicators={false} fade>
      {props.images &&
        props.images.map((image, index) => (
          <Carousel.Item interval={interval} key={index}>
            <img
              height={props.height ? props.height : "auto"}
              src={`${image}?${Math.random()}`}
              alt=""
              className="carousel-img"
              onError={(event) => {
                handleImageError(event, image);
              }}
            />
          </Carousel.Item>
        ))}
    </Carousel>
  );
};

CarouselComponent.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  updateTimeInSeconds: PropTypes.number.isRequired,
  height: PropTypes.string,
};

export default CarouselComponent;
