// ENCRYPT

const ENCRYPT_KEY = "OPEN_IT_GROUP_APP_TV";

// API

const API_URL = "https://viewshot.com.br/apptv/api";
const API_LOGIN_ENDPOINT = "login.php";
const API_TOKEN_ENDPOINT = "verificar_token.php";
const API_SCREEN_ENDPOINT = "tvHandler.php";
const API_DISPLAYS_ENDPOINT = "pinHandler.php";
const API_SETTING_ENDPOINT = "telasHandler.php";

// LOCAL STORAGE

const AUTH_LOCAL_STORAGE = "auth_local";
const SCREEN_LOCAL_STORAGE = "screen_local";

export {
  ENCRYPT_KEY,
  API_URL,
  API_LOGIN_ENDPOINT,
  API_TOKEN_ENDPOINT,
  API_SCREEN_ENDPOINT,
  API_DISPLAYS_ENDPOINT,
  API_SETTING_ENDPOINT,
  AUTH_LOCAL_STORAGE,
  SCREEN_LOCAL_STORAGE,
};
