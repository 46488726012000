import { API_URL } from "../../constants/constants";
import { getAuthLocalStorage } from "../localStorage/authLocalStorage";

function getToken() {
  const auth = getAuthLocalStorage();
  return auth.token;
}

function postAPI(endpoint, body) {
  return fetch(`${API_URL}/${endpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: body.toString(),
  });
}

function getAPI(endpoint, body) {
  return fetch(`${API_URL}/${endpoint}?${body.toString()}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function postAPIwithAuthorization(endpoint, body) {
  const jwtToken = getToken();

  return fetch(`${API_URL}/${endpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
    body: JSON.stringify(body),
  });
}

function deleteAPIwithAuthorization(endpoint, body) {
  const jwtToken = getToken();

  return fetch(`${API_URL}/${endpoint}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
    body: JSON.stringify(body),
  });
}

function putAPIwithAuthorization(endpoint, body) {
  const jwtToken = getToken();

  return fetch(`${API_URL}/${endpoint}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
    body: JSON.stringify(body),
  });
}

function getAPIwithAuthorization(endpoint, body) {
  const jwtToken = getToken();
  return fetch(`${API_URL}/${endpoint}?${body.toString()}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
  });
}

export {
  postAPI,
  getAPI,
  postAPIwithAuthorization,
  deleteAPIwithAuthorization,
  putAPIwithAuthorization,
  getAPIwithAuthorization,
};
