import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";

import "./Input.css";

const InputComponent = ({ icon: Icon, secondIcon: SecondIcon, ...props }) => {
  const [showSecond, setShowSecond] = React.useState(false);
  const handleClickIcon = () => setShowSecond((show) => !show);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleOnChange = (event) => {
    if (props.type === "number") {
      props.setValue(Number(event.target.value));
    } else {
      props.setValue(event.target.value);
    }
  };

  return (
    <FormControl variant="outlined" className="input-container">
      <InputLabel
        htmlFor={`outlined-adornment-${props.label}`}
        className="input-label"
      >
        {props.label}
      </InputLabel>
      <form className="input-form" onSubmit={handleSubmit}>
        <OutlinedInput
          autoComplete="off"
          id={`outlined-adornment-${props.label}`}
          type={props.secondType && showSecond ? props.secondType : props.type}
          className="input-outlined-container"
          value={props.value}
          label={props.label}
          onChange={handleOnChange}
          inputProps={{ min: props.min }}
          endAdornment={
            Icon && (
              <IconButton
                className="input-button-icon"
                aria-label="toggle SecondIcon visibility"
                onClick={handleClickIcon}
                edge="end"
              >
                {SecondIcon && showSecond ? <SecondIcon /> : <Icon />}
              </IconButton>
            )
          }
        />
      </form>
    </FormControl>
  );
};

InputComponent.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  min: PropTypes.string,

  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setValue: PropTypes.func.isRequired,

  secondType: PropTypes.string,
  secondIcon: PropTypes.elementType,
};

export default InputComponent;
