import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";

import PropTypes from "prop-types";
import "./Button.css";

const ButtonComponent = ({ icon: Icon, ...props }) => {
  const [key, setKey] = useState("");

  const handleKeyPress = (event) => {
    setKey(event.key);
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (props.onClick && key === "Enter") {
      props.onClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return (
    <Button
      variant="contained"
      onClick={props.onClick}
      className={`button-container ${props.className}`}
      endIcon={Icon && <Icon />}
    >
      {props.label}
    </Button>
  );
};

ButtonComponent.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.elementType,
  isEnterTriggered: PropTypes.bool,
};

export default ButtonComponent;
