import React from "react";

import { useAlert } from "../../../contexts/AlertProvider";
import ModalComponent from "../../../components/Modal/Modal";

import PropTypes from "prop-types";
import ModalAddEditBodySettings from "./ModalAddEditBody";
import { addSettingsAPI } from "../../../services/api/settingService";

const ModalAddSettings = ({ ...props }) => {
  const { doErrorAlertVisible, doSuccessAlertVisible } = useAlert();
  const [modalShow, setModalShow] = React.useState(false);

  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [selectType, setSelectType] = React.useState(1);
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [screenLogin, setScreenLogin] = React.useState("");
  const [screenReport, setScreenReport] = React.useState("");

  const addSettings = () => {
    if (!name || !description || !screenReport) {
      doErrorAlertVisible(
        "Os campos 'nome', 'descrição' e 'tela de relatório' não podem estar vazios!"
      );
      return;
    }
    const settings = {
      content: {
        user: userName,
        password: password,
        screenLogin: screenLogin,
        screenReport: screenReport,
      },
      type: selectType,
      name,
      description,
    };

    addSettingsAPI(props.screenID, settings)
      .then((response) => {
        props.getAllSettings(props.screenID);
        setModalShow(false);
        setName("");
        setDescription("");
        setPassword("");
        setScreenLogin("");
        setScreenReport("");
        doSuccessAlertVisible("Configuração adicionada com sucesso!");
      })
      .catch((error) => {
        doErrorAlertVisible("Erro ao adicionar configuração!");
        console.log("Error:", error);
      });
  };

  const handleShowModal = () => {
    setModalShow(true);
  };

  return (
    <span>
      <ModalComponent
        onClick={() => {}}
        modalShow={modalShow}
        setModalShow={setModalShow}
        title={"Adicionar Configuração"}
        body={
          <ModalAddEditBodySettings
            description={description}
            setDescription={setDescription}
            name={name}
            setName={setName}
            selectType={selectType}
            setSelectType={setSelectType}
            userName={userName}
            setUserName={setUserName}
            password={password}
            setPassword={setPassword}
            screenLogin={screenLogin}
            setScreenLogin={setScreenLogin}
            screenReport={screenReport}
            setScreenReport={setScreenReport}
            buttonLabel={"Adicionar"}
            onClick={addSettings}
          />
        }
      />

      <span onClick={handleShowModal}>{props.child}</span>
    </span>
  );
};

ModalAddSettings.propTypes = {
  child: PropTypes.element.isRequired,
  screenID: PropTypes.number.isRequired,
  getAllSettings: PropTypes.func.isRequired,
};

export default ModalAddSettings;
