import { AUTH_LOCAL_STORAGE } from "../../constants/constants";
import { getLocalStorageItem, setLocalStorageItem } from "./localStorage";

function setAuthLocalStorage(auth) {
  return setLocalStorageItem(AUTH_LOCAL_STORAGE, auth);
}

function getAuthLocalStorage() {
  return getLocalStorageItem(AUTH_LOCAL_STORAGE);
}

export { setAuthLocalStorage, getAuthLocalStorage };
