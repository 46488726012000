import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useAlert } from "../../contexts/AlertProvider";

import "./Alert.css";

const AlertComponent = () => {
  const { isAlertVisible, alertText, alertType } = useAlert();

  if (!isAlertVisible) {
    return <></>;
  }

  return (
    <Stack className="alert-container">
      <Alert severity={alertType} variant="filled" className="alert">
        {alertText}
      </Alert>
    </Stack>
  );
};

export default AlertComponent;
