import { encryptData, decryptData } from "../../utils/crypto";

function setLocalStorageItem(key, value) {
  try {
    const dataEncrypted = encryptData(value);
    localStorage.setItem(key, JSON.stringify(dataEncrypted));
    return dataEncrypted;
  } catch (error) {
    console.error("Erro ao definir item no localStorage:", error);
    return false;
  }
}

function getLocalStorageItem(key) {
  try {
    const item = localStorage.getItem(key);

    if (item && item !== null) {
      const dataDecrypted = decryptData(JSON.parse(item));
      return dataDecrypted;
    }

    return false;
  } catch (error) {
    console.error("Erro ao obter item do localStorage:", error);
    return false;
  }
}

function clearLocalStorage() {
  localStorage.clear();
}

export { setLocalStorageItem, getLocalStorageItem, clearLocalStorage };
