import {
  API_LOGIN_ENDPOINT,
  API_TOKEN_ENDPOINT,
} from "../../constants/constants";
import { postAPI } from "./api";

function loginUserAPI(username, password) {
  const body = new URLSearchParams();
  body.append("username", username);
  body.append("password", password);

  return postAPI(API_LOGIN_ENDPOINT, body)
    .then(async (response) => {
      const responseJson = await response.json();
      if (
        responseJson &&
        responseJson.status &&
        responseJson.status === "success"
      ) {
        return {
          id: responseJson.id_cliente,
          token: responseJson.token,
        };
      }

      throw responseJson.message;
    })
    .catch((error) => {
      console.error("Erro ao tentar logar:", error);
      throw error;
    });
}

function tokenVerifyAPI(token) {
  const body = new URLSearchParams();
  body.append("token", token);

  return postAPI(API_TOKEN_ENDPOINT, body)
    .then(async (response) => {
      const responseJson = await response.json();
      
      if (responseJson && responseJson.valido) {
        return {
          expiration: responseJson.payload.exp,
          userName: responseJson.payload.username,
        };
      }

      throw responseJson.message;
    })
    .catch((error) => {
      console.error("Erro ao verificar token:", error);
      throw error;
    });
}

export { loginUserAPI, tokenVerifyAPI };
