import React from "react";

import ButtonComponent from "../../../components/Button/Button";

import { deleteScreenAPI } from "../../../services/api/screenService";
import { useAlert } from "../../../contexts/AlertProvider";
import ModalComponent from "../../../components/Modal/Modal";
import { formatDateToString } from "../../../utils/utils";
import FormGroup from "@mui/material/FormGroup";
import PropTypes from "prop-types";

import "./Modals.css";

const ModalDeleteScreen = ({ ...props }) => {
  const { doErrorAlertVisible, doSuccessAlertVisible } = useAlert();
  const [modalShow, setModalShow] = React.useState(false);

  const deleteScreen = () => {
    deleteScreenAPI(props.id)
      .then((response) => {
        props.setScreens((prevScreens) =>
          prevScreens.filter((newScreen) => newScreen.id !== props.id)
        );
        doSuccessAlertVisible("Tela excluída com sucesso!");
        setModalShow(false);
      })
      .catch((error) => {
        doErrorAlertVisible("Erro ao excluir tela!");
        console.log("Error: ", error);
      });
  };

  const handleShowModal = () => {
    setModalShow(true);
  };

  return (
    <span>
      <ModalComponent
        onClick={() => {}}
        modalShow={modalShow}
        setModalShow={setModalShow}
        title={"Deletar Tela"}
        body={
          <FormGroup className="modal-delete-screen-form">
            A tela <span className="modal-delete-screen-span">{props.name}</span> será deletada em
            <span className="modal-delete-screen-span">{" "+formatDateToString(new Date())}</span>.
            Deseja confirmar esta ação?
            <ButtonComponent
              label="Excluir"
              className="modal-delete-screen-button-red"
              onClick={deleteScreen}
            />
          </FormGroup>
        }
      />

      <span onClick={handleShowModal}>{props.child}</span>
    </span>
  );
};

ModalDeleteScreen.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  child: PropTypes.element.isRequired,
  setScreens: PropTypes.func.isRequired,
};

export default ModalDeleteScreen;
