import React from "react";

import ButtonComponent from "../../../components/Button/Button";
import InputComponent from "../../../components/Input/Input";
import SelectComponent from "../../../components/Select/Select";

import FormGroup from "@mui/material/FormGroup";
import PropTypes from "prop-types";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import "./Modals.css";
import { SettingTypeEnum } from "../../../models/Setting";

const ModalAddEditBodySettings = ({ ...props }) => {
  const selectItems = [
    { value: SettingTypeEnum.GRAFANA.value, label: SettingTypeEnum.GRAFANA.label },
    { value: SettingTypeEnum.ZABBIX.value, label: SettingTypeEnum.ZABBIX.label },
    { value: SettingTypeEnum.LOOKER.value, label: SettingTypeEnum.LOOKER.label },
    { value: SettingTypeEnum.POWERBI.value, label: SettingTypeEnum.POWERBI.label },
  ];

  return (
    <FormGroup>
      <div className="modal-add-edit-body-select">
        <SelectComponent
          label="Tipo"
          value={props.selectType}
          setValue={props.setSelectType}
          items={selectItems}
        />
      </div>

      <InputComponent
        label="Nome"
        type="text"
        value={props.name}
        setValue={props.setName}
      />

      <InputComponent
        label="Descrição"
        type="text"
        value={props.description}
        setValue={props.setDescription}
      />

      <InputComponent
        label="Tela de Relatório"
        type="text"
        value={props.screenReport}
        setValue={props.setScreenReport}
      />
      {(props.selectType === selectItems[0].value ||
        props.selectType === selectItems[1].value) && (
          <>
            <InputComponent
              label="Tela de Login"
              type="text"
              value={props.screenLogin}
              setValue={props.setScreenLogin}
            />

            <InputComponent
              label="Usuário"
              type="text"
              value={props.userName}
              setValue={props.setUserName}
            />

            <InputComponent
              label="Senha"
              type="password"
              secondType="text"
              value={props.password}
              setValue={props.setPassword}
              icon={VisibilityIcon}
              secondIcon={VisibilityOffIcon}
            />

          </>
        )}

      <div className="modal-add-edit-body-button">
        <ButtonComponent
          label={props.buttonLabel}
          onClick={props.onClick}
          size={"small"}
          isEnterTriggered={true}
        />
      </div>
    </FormGroup>
  );
};

ModalAddEditBodySettings.propTypes = {
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,

  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,

  selectType: PropTypes.number.isRequired,
  setSelectType: PropTypes.func.isRequired,

  userName: PropTypes.string.isRequired,
  setUserName: PropTypes.func.isRequired,

  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,

  screenLogin: PropTypes.string.isRequired,
  setScreenLogin: PropTypes.func.isRequired,

  screenReport: PropTypes.string.isRequired,
  setScreenReport: PropTypes.func.isRequired,

  buttonLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ModalAddEditBodySettings;
