import React, { createContext, useContext, useState } from "react";
import { loginUserAPI, tokenVerifyAPI } from "../services/api/authService";
import {
  getAuthLocalStorage,
  setAuthLocalStorage,
} from "../services/localStorage/authLocalStorage";
import { clearLocalStorage } from "../services/localStorage/localStorage";
import Auth from "../models/Auth";
import { useAlert } from "./AlertProvider";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [auth, setAuth] = useState(null);
  const { doErrorAlertVisible } = useAlert();

  const login = async (username, password) => {
    try {
      const { id, token } = await loginUserAPI(username, password);
      const { expiration, userName } = await tokenVerifyAPI(token);

      const auth = new Auth({
        id,
        token,
        expiration,
        userName,
      });

      setAuthLocalStorage(auth);
      startSession(auth);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const sessionVerify = () => {
    if (!auth || auth === null) {
      const authLocal = getAuthLocalStorage();
      if (authLocal && authLocal !== null) {
        startSession(authLocal);
      }
    }

    return true;
  };

  const startSession = (auth) => {
    setAuth(auth);
    setIsAuthenticated(true);
    endSession(auth.expiration * 1000, () => {
      doErrorAlertVisible("Sua sessão expirou, entre novamente em sua conta");
      logout();
    });
  };

  const endSession = (endTimeMilliseconds, callback) => {
    const time = endTimeMilliseconds - Date.now();
    if (time <= 0) {
      callback();
    } else {
      setTimeout(callback, time);
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    clearLocalStorage();
  };

  return (
    <AuthContext.Provider
      value={{ login, isAuthenticated, sessionVerify, logout, auth }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth deve ser usado dentro de um AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
