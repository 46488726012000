import { API_DISPLAYS_ENDPOINT } from "../../constants/constants";
import { swapObjectAttributes } from "../../utils/utils";
import { getAPI } from "./api";

const swapAttributesDisplays = {
  id: "id",
  url: "url",
  tempo_atualizacao: "updateTime",
};

function getDisplayByPIN(pin) {
  const body = new URLSearchParams();
  body.append("pin", pin);

  return getAPI(API_DISPLAYS_ENDPOINT, body)
    .then(async (response) => {
      const responseJson = await response.json();
      if (
        responseJson &&
        responseJson.status &&
        responseJson.status === "success"
      ) {
        const data = swapObjectAttributes(
          responseJson.data,
          swapAttributesDisplays
        );

        return data;
      }

      throw responseJson.message;
    })
    .catch((error) => {
      console.error("Erro ao listar imagens:", error);
      throw error;
    });
}

export { getDisplayByPIN };
