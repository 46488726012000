import { API_SETTING_ENDPOINT } from "../../constants/constants";
import { swapObjectAttributes } from "../../utils/utils";
import { getAuthLocalStorage } from "../localStorage/authLocalStorage";
import {
  deleteAPIwithAuthorization,
  getAPIwithAuthorization,
  postAPIwithAuthorization,
  putAPIwithAuthorization,
} from "./api";

function getUserID() {
  const auth = getAuthLocalStorage();
  return auth.id;
}

function addSettingsAPI(screenID, settings) {
  const content = {
    usuario: settings.type < 3 ? settings.content.user : "",
    senha: settings.type < 3 ? settings.content.password : "",
    tela_login: settings.type < 3 ? settings.content.screenLogin : "",
    tela_relatorio: settings.content.screenReport,
  };

  const requestBody = {
    id_tv: screenID,
    id_cliente: getUserID(),
    conteudo: JSON.stringify(content),
    tipo: settings.type,
    nome: settings.name,
    descricao: settings.description,
  };

  return postAPIwithAuthorization(API_SETTING_ENDPOINT, requestBody)
    .then(async (response) => {
      const responseJson = await response.json();

      if (
        responseJson &&
        responseJson.status &&
        responseJson.status === "success"
      ) {
        return responseJson.message;
      }

      throw responseJson.message;
    })
    .catch((error) => {
      console.error("Erro ao adicionar uma configuração:", error);
      throw error;
    });
}

function getSettingsAPI(screenID) {
  const body = new URLSearchParams();
  body.append("id_tv", screenID);

  return getAPIwithAuthorization(API_SETTING_ENDPOINT, body)
    .then(async (response) => {
      const responseJson = await response.json();
      if (
        responseJson &&
        responseJson.status &&
        responseJson.status === "success"
      ) {
        const data = swapObjectAttributes(responseJson.data, {
          id: "id",
          id_tv: "screenID",
          tipo: "type",
          conteudo: "content",
          nome: "name",
          descricao: "description",
          usuario: "user",
          senha: "password",
          tela_login: "screenLogin",
          tela_relatorio: "screenReport",
          url: "image",
          ultima_atualizacao: "lastUpdate",
        });

        return data;
      }

      throw responseJson.status;
    })
    .catch((error) => {
      console.error("Erro ao listar configurações da tela:", error);
      throw error;
    });
}

function editSettingsAPI(settings) {
  const content = {
    usuario: settings.type < 3 ? settings.content.user : "",
    senha: settings.type < 3 ? settings.content.password : "",
    tela_login: settings.type < 3 ? settings.content.screenLogin : "",
    tela_relatorio: settings.content.screenReport,
  };

  const requestBody = {
    id: settings.id,
    id_tv: settings.screenID,
    id_cliente: getUserID(),
    conteudo: JSON.stringify(content),
    tipo: settings.type,
    nome: settings.name,
    descricao: settings.description,
  };

  return putAPIwithAuthorization(API_SETTING_ENDPOINT, requestBody)
    .then(async (response) => {
      const responseJson = await response.json();
      if (
        responseJson &&
        responseJson.status &&
        responseJson.status === "success"
      ) {
        return responseJson.message;
      }

      throw responseJson.message;
    })
    .catch((error) => {
      console.error("Erro ao editar uma configuração:", error);
      throw error;
    });
}

function deleteSettingsAPI(id) {
  const requestBody = {
    id,
  };

  return deleteAPIwithAuthorization(API_SETTING_ENDPOINT, requestBody)
    .then(async (response) => {
      const responseJson = await response.json();
      if (
        responseJson &&
        responseJson.status &&
        responseJson.status === "success"
      ) {
        return responseJson.message;
      }

      throw responseJson.message;
    })
    .catch((error) => {
      console.error("Erro ao deletar uma configuração:", error);
      throw error;
    });
}

export { getSettingsAPI, addSettingsAPI, deleteSettingsAPI, editSettingsAPI };
