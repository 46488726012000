import React from "react";
import { AuthProvider } from "./contexts/AuthProvider";
import { AlertProvider } from "./contexts/AlertProvider";
import RoutesComponent from "./components/Routes/Routes";

function App() {
  return (
    <AlertProvider>
      <AuthProvider>
        <RoutesComponent />
      </AuthProvider>
    </AlertProvider>
  );
}

export default App;
