import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "../../pages/Login/Login";
import DisplaysPage from "../../pages/Displays/Displays";

function AuthRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="*" element={<LoginPage />} />
      <Route path="/displays" element={<DisplaysPage />} />
    </Routes>
  );
}

export default AuthRoutes;
