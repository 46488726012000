import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import PropTypes from "prop-types";
import { useAuth } from "../../contexts/AuthProvider";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useLocation } from "react-router-dom";

import "./Navbar.css";

const NavbarComponent = (props) => {
  const { logout } = useAuth();
  const [pathName, setPathName] = useState();
  const location = useLocation();

  const doLogOut = async () => {
    logout();
  };

  const isActive = (href) => {
    return pathName === href;
  };

  useEffect(() => {
    setPathName(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <Navbar expand="lg" sticky="top" data-bs-theme="dark" className="navbar">
        <Container className="navbar-container">
          <Navbar.Brand className="navbar-label"> {props.label}</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {props.options &&
                props.options.map((option, index) => (
                  <Nav.Link
                    href={`${option.link}`}
                    active={isActive(option.link)}
                    key={index}
                  >
                    {option.label}
                  </Nav.Link>
                ))}
            </Nav>
            <Nav className="justify-content-end">
              <Nav.Link onClick={doLogOut}>
                <div className="nav-exit-container">
                  SAIR
                  <ExitToAppIcon className="nav-icon" />
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

NavbarComponent.propTypes = {
  label: PropTypes.string.isRequired,

  options: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

export default NavbarComponent;
