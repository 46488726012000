import React, { useEffect } from "react";

import { useAlert } from "../../../contexts/AlertProvider";
import ModalComponent from "../../../components/Modal/Modal";

import PropTypes from "prop-types";
import { editScreenAPI } from "../../../services/api/screenService";
import ModalAddEditBodyScreen from "./ModalAddEditBody";

const ModalEditScreen = ({ ...props }) => {
  const { doErrorAlertVisible, doSuccessAlertVisible } = useAlert();
  const [modalShow, setModalShow] = React.useState(false);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [updateTime, setUpdateTime] = React.useState(0);

  useEffect(() => {
    setName(props.screen.name);
    setDescription(props.screen.description);
    setUpdateTime(props.screen.updateTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.screen]);

  const editScreen = () => {
    if (!name || !description) {
      doErrorAlertVisible("Os campos não podem estar vazios!");
      return;
    }

    if (updateTime <= 0) {
      doErrorAlertVisible("O tempo de atualização deve ser maior que 0!");
      return;
    }

    editScreenAPI(props.screen.id, name, description, updateTime)
      .then(() => {
        const screen = {
          id: props.screen.id,
          name,
          description,
          updateTime,
          pin: props.screen.pin
        };

        props.setScreens((prevScreens) =>
          prevScreens.map((newScreen) =>
            newScreen.id === props.screen.id ? { ...screen } : newScreen
          )
        );

        setModalShow(false);
        doSuccessAlertVisible("Tela editada com sucesso!");
      })
      .catch((error) => {
        doErrorAlertVisible("Erro ao editar tela!");
        console.log("Error: ", error);
      });
  };

  const handleShowModal = () => {
    setName(props.screen.name);
    setDescription(props.screen.description);
    setModalShow(true);
  };

  return (
    <span>
      <ModalComponent
        onClick={() => {}}
        modalShow={modalShow}
        setModalShow={setModalShow}
        title={"Editar Tela"}
        body={
          <ModalAddEditBodyScreen
            description={description}
            setDescription={setDescription}
            name={name}
            setName={setName}
            updateTime={updateTime}
            setUpdateTime={setUpdateTime}
            buttonLabel="Editar"
            onClick={editScreen}
          />
        }
      />

      <span onClick={handleShowModal}>{props.child}</span>
    </span>
  );
};

ModalEditScreen.propTypes = {
  screen: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  child: PropTypes.element.isRequired,
  setScreens: PropTypes.func.isRequired,
};

export default ModalEditScreen;
