import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider";
import LoadComponent from "../Load/Load";
import AuthRoutes from "./AuthRoutes";
import PrivateRoutes from "./PrivateRoutes";
import AlertComponent from "../Alert/Alert";

function RoutesComponent() {
  const { isAuthenticated, sessionVerify } = useAuth();
  const [isLoad, setIsLoad] = useState(true);

  useEffect(() => {
    setIsLoad(!sessionVerify());
  }, [sessionVerify]);

  if (isLoad) {
    return <LoadComponent isLoad={isLoad}></LoadComponent>;
  }

  return (
    <Router>
      <AlertComponent />
      {isAuthenticated && isAuthenticated !== null ? (
        <PrivateRoutes />
      ) : (
        <AuthRoutes />
      )}
    </Router>
  );
}

export default RoutesComponent;
