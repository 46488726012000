import PropTypes from "prop-types";

class Screen {
  constructor({ id, name, description, pin, updateTime }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.pin = pin;
    this.updateTime = updateTime;
  }

  static setObject(data) {
    return new Screen({
      id: data.id,
      name: data.name,
      description: data.description,
      pin: data.pin,
      updateTime: data.updateTime
    });
  }

  static createObjectFromArray(dataArray) {
    return dataArray.map((data) => new Screen(data));
  }
}

Screen.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  pin: PropTypes.string.isRequired,
  updateTime: PropTypes.number.isRequired,
};

export default Screen;
