import PropTypes from "prop-types";

export const SettingTypeEnum = {
  GRAFANA: { label: "Grafana", value: 1 },
  ZABBIX: { label: "Zabbix", value: 2 },
  LOOKER: { label: "Looker", value: 3 },
  POWERBI: { label: "PowerBi", value: 4 },
};

class Setting {
  constructor({ id, screenID, type, name, image, lastUpdate, description, content }) {
    this.id = id;
    this.screenID = screenID;
    this.type = type;
    this.name = name;
    this.description = description;
    this.image = image;
    this.lastUpdate = lastUpdate;
    this.content = {
      user: content.user,
      password: content.password,
      screenLogin: content.screenLogin,
      screenReport: content.screenReport,
    };
  }

  static setObject(data) {
    return new Setting({
      id: data.id,
      screenID: data.screenID,
      type: data.type,
      name: data.name,
      image: data.image,
      description: data.description,
      content: data.content,
      lastUpdate: data.lastUpdate,
    });
  }

  static createObjectFromArray(dataArray) {
    return dataArray.map((data) => new Setting(data));
  }
}

Setting.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  lastUpdate: PropTypes.string,
  content: PropTypes.shape({
    user: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    screenLogin: PropTypes.string.isRequired,
    screenReport: PropTypes.string.isRequired,
  }).isRequired,
};

export default Setting;
