import { API_SCREEN_ENDPOINT } from "../../constants/constants";
import { swapObjectAttributes } from "../../utils/utils";
import { getAuthLocalStorage } from "../localStorage/authLocalStorage";
import {
  deleteAPIwithAuthorization,
  getAPIwithAuthorization,
  postAPIwithAuthorization,
  putAPIwithAuthorization,
} from "./api";

const swapAttributesScreen = {
  id: "id",
  nome: "name",
  descricao: "description",
  pin: "pin",
  tempo_atualizacao: "updateTime",
};

function getUserID() {
  const auth = getAuthLocalStorage();
  return auth.id;
}

function getScreensAPI() {
  const body = new URLSearchParams();
  body.append("id_cliente", getUserID());

  return getAPIwithAuthorization(API_SCREEN_ENDPOINT, body)
    .then(async (response) => {
      const responseJson = await response.json();
      if (
        responseJson &&
        responseJson.status &&
        responseJson.status === "success"
      ) {
        const data = swapObjectAttributes(
          responseJson.data,
          swapAttributesScreen
        );

        return data;
      }

      throw responseJson.message;
    })
    .catch((error) => {
      console.error("Erro ao listat telas:", error);
      throw error;
    });
}

function getScreenAPI(ScreenID) {
  const body = new URLSearchParams();
  body.append("id_tv", ScreenID);

  return getAPIwithAuthorization(API_SCREEN_ENDPOINT, body)
    .then(async (response) => {
      const responseJson = await response.json();
      if (
        responseJson &&
        responseJson.status &&
        responseJson.status === "success"
      ) {
        const data = swapObjectAttributes(
          responseJson.data[0],
          swapAttributesScreen
        );

        return data;
      }

      throw responseJson.message;
    })
    .catch((error) => {
      throw error;
    });
}

function addScreenAPI(name, description, updateTime) {
  const requestBody = {
    id_cliente: getUserID(),
    nome: name,
    descricao: description,
    tempo_atualizacao: updateTime,
  };

  return postAPIwithAuthorization(API_SCREEN_ENDPOINT, requestBody)
    .then(async (response) => {
      const responseJson = await response.json();

      if (
        responseJson &&
        responseJson.status &&
        responseJson.status === "success"
      ) {
        return responseJson.message;
      }

      throw responseJson.message;
    })
    .catch((error) => {
      console.error("Erro ao adicionar uma tela:", error);
      throw error;
    });
}

function deleteScreenAPI(tvID) {
  const requestBody = {
    id: tvID,
  };

  return deleteAPIwithAuthorization(API_SCREEN_ENDPOINT, requestBody)
    .then(async (response) => {
      const responseJson = await response.json();
      if (
        responseJson &&
        responseJson.status &&
        responseJson.status === "success"
      ) {
        return responseJson.message;
      }

      throw responseJson.message;
    })
    .catch((error) => {
      console.error("Erro ao deletar uma tela:", error);
      throw error;
    });
}

function editScreenAPI(tvID, name, description, updateTime) {
  const requestBody = {
    id: tvID,
    nome: name,
    descricao: description,
    tempo_atualizacao: updateTime,
  };

  return putAPIwithAuthorization(API_SCREEN_ENDPOINT, requestBody)
    .then(async (response) => {
      const responseJson = await response.json();
      if (
        responseJson &&
        responseJson.status &&
        responseJson.status === "success"
      ) {
        return responseJson.message;
      }

      throw responseJson.message;
    })
    .catch((error) => {
      console.error("Erro ao editar uma tela:", error);
      throw error;
    });
}

export {
  addScreenAPI,
  deleteScreenAPI,
  editScreenAPI,
  getScreensAPI,
  getScreenAPI,
};
