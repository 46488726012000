import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FormGroup from "@mui/material/FormGroup";
import PersonIcon from "@mui/icons-material/Person";

import { useAuth } from "../../contexts/AuthProvider";
import TVImage from "../../assets/images/tv-login.png";
import Logo from "../../assets/images/openIT-logo.jpeg";

import Container from "@mui/material/Container";
import InputComponent from "../../components/Input/Input";
import ButtonComponent from "../../components/Button/Button";

import "./Login.css";
import { useAlert } from "../../contexts/AlertProvider";

const LoginPage = () => {
  const { login } = useAuth();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const { doErrorAlertVisible } = useAlert();

  const doLogin = () => {
    if (!user || !password) {
      doErrorAlertVisible("Os campos não podem estar em branco!");
    }
    login(user, password)
      .then(() => {})
      .catch(() => {
        doErrorAlertVisible("Erro ao logar, verifique os campos!");
      });
  };

  return (
    <>
      <Grid
        container
        spacing={4}
        sx={{ flexGrow: 1 }}
        className="login-container"
      >
        <Grid
          xs={12}
          sm={12}
          lg={7}
          md={7}
          xl={7}
          className="login-image-container"
          order={{ xl: 1, md: 1, lg: 1, sm: 1, xs: 2 }}
        >
          <img
            className="login-image"
            src={TVImage}
            alt="Descrição da imagem"
            width={"100%"}
          />
        </Grid>
        <Grid
          xs={12}
          sm={12}
          lg={5}
          md={5}
          xl={5}
          className="login-form-grid"
          order={{ xl: 2, md: 2, lg: 2, sm: 2, xs: 1 }}
        >
          <Container fixed className="login-form-container">
            <div className="login-logo-image-container">
              <img
                className="login-logo-image"
                src={Logo}
                alt="Descrição da imagem"
                width="100"
              />
            </div>

            <FormGroup>
              <InputComponent
                label="Usuário"
                type="text"
                icon={PersonIcon}
                value={user}
                setValue={setUser}
              />
              <InputComponent
                label="Senha"
                type="password"
                secondType="text"
                icon={VisibilityIcon}
                secondIcon={VisibilityOffIcon}
                value={password}
                setValue={setPassword}
              />
              {/* <CheckBox
                label="Lembrar usuário"
                isChecked={savePassword}
                setIsChecked={setSavePassword}
              /> */}
              <div className="login-button-container">
                <div className="login-button">
                  <ButtonComponent
                    label="Entrar"
                    isEnterTriggered={true}
                    onClick={doLogin}
                    size={"small"}
                    className={"login-button-color"}
                  />
                </div>
              </div>
            </FormGroup>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginPage;
