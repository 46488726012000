import React from "react";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import ButtonComponent from "../../../components/Button/Button";
import InputComponent from "../../../components/Input/Input";
import FormGroup from "@mui/material/FormGroup";

import PropTypes from "prop-types";

import "./Modals.css";

const ModalAddEditBodyScreen = ({ ...props }) => {
  return (
    <FormGroup>
      <InputComponent
        label="Nome"
        type="text"
        value={props.name}
        setValue={props.setName}
      />
      <InputComponent
        label="Descrição"
        type="text"
        value={props.description}
        setValue={props.setDescription}
      />

      <InputComponent
        min={"1"}
        label="Tempo de atualização (segundos)"
        type="number"
        value={props.updateTime}
        setValue={props.setUpdateTime}
      />

      {props.pin && (
        <InputComponent
          label="Senha"
          type="password"
          secondType="text"
          icon={VisibilityIcon}
          secondIcon={VisibilityOffIcon}
          value={props.pin}
          setValue={props.setPin}
        />
      )}

      <div className="modal-add-edit-body-button">
        <ButtonComponent
          label={props.buttonLabel}
          onClick={props.onClick}
          size={"small"}
          isEnterTriggered={true}
        />
      </div>
    </FormGroup>
  );
};

ModalAddEditBodyScreen.propTypes = {
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  updateTime: PropTypes.number.isRequired,
  setUpdateTime: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,

  pin: PropTypes.string,
  setPin: PropTypes.func,
};

export default ModalAddEditBodyScreen;
