import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import PropTypes from "prop-types";

import "./Select.css";

const SelectComponent = ({ ...props }) => {
  const handleChange = (event) => {
    props.setValue(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={`demo-simple-select-label ${props.label}`}>
        {props.label}
      </InputLabel>
      <Select
        labelId={`demo-simple-select-label ${props.label}`}
        id="demo-simple-select"
        value={props.value}
        label={props.label}
        onChange={handleChange}
      >
        {props.items.map((item, index) => (
          <MenuItem value={item.value} key={index}>{item.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectComponent.propTypes = {
  label: PropTypes.string.isRequired,

  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setValue: PropTypes.func.isRequired,

  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ).isRequired,
};

export default SelectComponent;
