import React, { useEffect } from "react";

import "./Screens.css";
import Grid from "@mui/material/Grid";
import CardComponent from "../../components/Card/Card";
import ButtonComponent from "../../components/Button/Button";

import Container from "@mui/material/Container";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { getScreensAPI } from "../../services/api/screenService";
import { useAlert } from "../../contexts/AlertProvider";
import LoadComponent from "../../components/Load/Load";
import { Link } from "react-router-dom";
import ModalDeleteScreen from "./Modals/ModalDelete";
import ModalEditScreen from "./Modals/ModalEdit";
import ModalAddScreen from "./Modals/ModalAdd";
import Screen from "../../models/Screen";
import { setScreenLocalStorage } from "../../services/localStorage/screenLocalStorage";

const ScreensPage = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [screens, setScreens] = React.useState([]);

  const { doErrorAlertVisible } = useAlert();

  useEffect(() => {
    if (screens.length === 0) {
      getScreens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getScreens = () => {
    getScreensAPI()
      .then((screens) => {
        setScreens(Screen.createObjectFromArray(screens));
      })
      .catch((error) => {
        doErrorAlertVisible("Erro ao carregar telas!");
        console.log("Error: ", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <LoadComponent isLoad={isLoading} />;
  }

  return (
    <Container className="screens-container">
      <ModalAddScreen
        child={
          <ButtonComponent
            label={"Adicionar Tela"}
            className="screens-button"
          />
        }
        setScreens={setScreens}
        getScreens={getScreens}
      />

      <Grid container className="screens-cards-container">
        {screens.map((screen, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            md={4}
            xl={4}
            key={index}
            className="screens-grid"
          >
            <CardComponent
              description={screen.description}
              body={
                <div>
                  <h6>Definições:</h6>
                  <div>PIN: {screen.pin}</div>
                  <div>Tempo de atualização: {screen.updateTime} segundos</div>
                </div>
              }
              title={screen.name}
              options={
                <div
                  className="screen-icons-container"
                  onClick={() => {
                    setScreenLocalStorage(screen);
                  }}
                >
                  <Link to={`/settings`} className="link-unstyled">
                    <div className="icon-container screen-setting-icon-container">
                      <SettingsIcon />
                      <div className="screen-setting-icon-text">Configurar</div>
                    </div>
                  </Link>

                  <ModalEditScreen
                    screen={screen}
                    child={
                      <div className="icon-container screen-edit-icon-container">
                        <EditIcon />
                        <div className="screen-edit-icon-text">Editar</div>
                      </div>
                    }
                    setScreens={setScreens}
                  />

                  <div className="screen-delete-icon-width">
                    <ModalDeleteScreen
                      id={screen.id}
                      name={screen.name}
                      child={
                        <div className="icon-container screen-delete-icon-container">
                          <DeleteIcon />
                          <div className="screen-delete-icon-text">Excluir</div>
                        </div>
                      }
                      setScreens={setScreens}
                    />
                  </div>
                </div>
              }
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ScreensPage;
