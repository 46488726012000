import React from "react";

import { useAlert } from "../../../contexts/AlertProvider";
import ModalComponent from "../../../components/Modal/Modal";

import PropTypes from "prop-types";
import { addScreenAPI } from "../../../services/api/screenService";
import ModalAddEditBodyScreen from "./ModalAddEditBody";

const ModalAddScreen = ({ ...props }) => {
  const { doErrorAlertVisible, doSuccessAlertVisible } = useAlert();
  const [modalShow, setModalShow] = React.useState(false);
  const [name, setName] = React.useState("");
  const [updateTime, setUpdateTime] = React.useState(10);
  const [description, setDescription] = React.useState("");

  const addScreen = () => {
    if (!name || !description) {
      doErrorAlertVisible("Os campos não podem estar vazios!");
      return;
    }

    if(updateTime <= 0){
      doErrorAlertVisible("O tempo de atualização deve ser maior que 0!");
      return;
    }

    addScreenAPI(name, description, updateTime)
      .then((response) => {
        props.getScreens();
        setModalShow(false);
        setName("");
        setDescription("");
        setUpdateTime(10);
        doSuccessAlertVisible("Tela adicionada com sucesso!");
      })
      .catch((error) => {
        doErrorAlertVisible("Erro ao adicionar tela!");
        console.log("Error:", error);
      });
  };

  const handleShowModal = () => {
    setModalShow(true);
  };

  return (
    <span>
      <ModalComponent
        onClick={() => {}}
        modalShow={modalShow}
        setModalShow={setModalShow}
        title={"Adicionar Tela"}
        body={
          <ModalAddEditBodyScreen
            description={description}
            setDescription={setDescription}
            name={name}
            setName={setName}
            updateTime={updateTime}
            setUpdateTime={setUpdateTime}
            buttonLabel="Adicionar"
            onClick={addScreen}
          />
        }
      />

      <span onClick={handleShowModal}>{props.child}</span>
    </span>
  );
};

ModalAddScreen.propTypes = {
  child: PropTypes.element.isRequired,
  setScreens: PropTypes.func.isRequired,
  getScreens: PropTypes.func.isRequired,
};

export default ModalAddScreen;
