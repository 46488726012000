import React, { createContext, useContext, useEffect, useState } from "react";

const AlertContext = createContext();

const AlertProvider = ({ children }) => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertText, setAlertText] = useState("error");
  const [alertType, setAlertType] = useState("error");

  useEffect(() => {
    if (isAlertVisible) {
      setTimeout(() => {
        setIsAlertVisible(false);
      }, 4000);
    }
  }, [isAlertVisible]);

  const doErrorAlertVisible = (text) => {
    setAlertText(text);
    setAlertType("error");
    setIsAlertVisible(true);
  };

  const doWarningAlertVisible = (text) => {
    setAlertText(text);
    setAlertType("warning");
    setIsAlertVisible(true);
  };

  const doInfoAlertVisible = (text) => {
    setAlertText(text);
    setAlertType("info");
    setIsAlertVisible(true);
  };

  const doSuccessAlertVisible = (text) => {
    setAlertText(text);
    setAlertType("success");
    setIsAlertVisible(true);
  };

  return (
    <AlertContext.Provider
      value={{
        isAlertVisible,
        alertText,
        alertType,
        doErrorAlertVisible,
        doWarningAlertVisible,
        doInfoAlertVisible,
        doSuccessAlertVisible,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert deve ser usado dentro de um AlertProvider");
  }
  return context;
};

export { AlertProvider, useAlert };
