import CryptoJS from "crypto-js";
import { ENCRYPT_KEY } from "../constants/constants";

const encryptData = (data) => {
  try {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      ENCRYPT_KEY
    ).toString();

    return encryptedData;
  } catch (error) {
    console.error("Erro ao criptografar dados:", error);
  }
};

const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPT_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (error) {
    console.error("Erro ao descriptografar dados:", error);
  }
};

export { encryptData, decryptData };
