import React from "react";
import { PinInput, PinInputField } from "@chakra-ui/react";
import PropTypes from "prop-types";

import "./PIN.css";

const PINComponent = ({ ...props }) => {
  return (
    <PinInput
      size="lg"
      onChange={(test) => {
        props.setCode(test);
      }}
    >
      {new Array(props.length).fill(0).map((value, id) => {
        return (
          <PinInputField key={id} className="pin-input" autoFocus={id === 0} />
        );
      })}
    </PinInput>
  );
};

PINComponent.propTypes = {
  length: PropTypes.number.isRequired,
  setCode: PropTypes.func.isRequired,
};

export default PINComponent;
